import './index.css'
const Header = () => {
    return (
        <div>
            <h1 className='headerFont'>Welcome, to antfire online transport services</h1>
        </div>
    )
}


export default Header