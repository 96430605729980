import './index.css'
const Content = () => {
    return (
        <div className='cardContainer'>
            <h1 className='contentCaption'>Ship your bike with safe hands and lowest price</h1>
        </div>
    )
}


export default Content