import { useState } from 'react'
import emailjs from '@emailjs/browser';
import './index.css'
import {Button} from '@mui/material';

const InputFiled = (props) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const [name, setName] = useState(userDetails?.name ? userDetails.name :'')
    const [phoneNo, setPhoneNo] = useState(userDetails?.phoneNo ? userDetails.phoneNo:'')
    const [email, setEmail] = useState(userDetails?.email ? userDetails.email: '')
    const [toAddress, setToAddress] = useState('')
    const [fromAddress, setFromAddress] = useState('')

    const submitDetails = (event) => {
        event.preventDefault();
        console.log("^^^^^^^^^^^^^^^^^^", name, phoneNo, email, "::")
        if (name !== '' && phoneNo !== '') {
            localStorage.setItem('userDetails', JSON.stringify({
                name,
                phoneNo,
                email
            }))
            const emailTemp = {
                from_name: name,
                to_name: 'folks',
                message: `Hello folks a new customer trying to reach us. Name ${name}, phone no ${phoneNo}`,
                User_email: ['duvvurutejeshreddy@gmail.com', 'duvvurutejeshreddy@outlook.com', 'srinivasuluallam800@gmail.com', 'sandeepganta619@gmail.com ', 'guruprakash50@gmail.com '],
                toaddress: toAddress,
                fromaddress: fromAddress,
            }
            emailjs.send('service_7vw7pia', 'template_n3t9ymh', emailTemp, 'PRdRi-DJha6bwA-5z')
                .then((response) => {
                    if (response.status === 200) {
                    alert('Our representative will reach you with in short period of time')
                }
                console.log('SUCCESS!', response.status, response.text);
             }, (err) => {
                console.log('FAILED...', err);
             });
        }    
    }

    return (     
        <form className='inputContainer'>
            <div className='details-container'>
                <div className='name-container'>
                    <label htmlFor='name' className='lable-style'>Name: </label>
                    <input  id='name' name= 'name' value={ name}  onChange={e => setName(e.target.value)} className='input-style'/>
                </div>
                <div className='phoneno-container'>
                    <label htmlFor='phoneno' className='lable-style'>Phone Number: </label>
                    <input  id='phoneno' name= 'phoneno' value={ phoneNo}   onChange={e => setPhoneNo(e.target.value)} className='input-style'/>
                </div>
                <div className='email-container'>
                    <label htmlFor='name' className='lable-style'>Email: </label>
                    <input  id='name' name= 'name' value={ email} type='email' onChange={e => setEmail(e.target.value)} className='input-style' style={{width:"250px"}}/>
                </div>
            </div>     
            <div className='location-details-container'>
                <div className='from-container'>
                        <label htmlFor='fromAddress' className='lable-style'>From Address: </label>
                        <input  id='fromAddress' name= 'From Address' value={ fromAddress}  onChange={e => setFromAddress(e.target.value)} className='input-style'/>
                </div>
                <div className='to-container'>
                        <label htmlFor='toAddress' className='lable-style'>To Address: </label>
                        <input  id='toAddress' name= 'To Address' value={ toAddress}  onChange={e => setToAddress(e.target.value)} className='input-style'/>
                </div>
                <Button variant="contained" className='button-style' style={{backgroundColor:'#92ba3c'}} onClick={ (event)=>{submitDetails(event)}}>Submit</Button>
            </div>  
         </form>
            
    
    )
}

export default InputFiled