import './App.css';
import Header from './Components/Header';
import Content from './Components/Content';
import Bottom from './Components/Bottom';
import InputFiled from './Components/InputField';
function App() {
  return (
    <div className="backgroundContainer">    
        <Header /> 
        <Content />
        <InputFiled /> 
      <div className='bottomContainerStyle'>
          <Bottom/>
      </div>
    </div> 
  );
}

export default App;
